<template>
    <!--<nav aria-label="Sidebar" class="flex grow flex-col justify-between">-->
    <nav aria-label="Sidebar" class="h-full grow flex-col justify-between rounded shadow-xl ring-1 ring-gray-200">
        <div class="flex grow flex-col overflow-y-auto rounded-t bg-white">
            <component
                :is="componentTag(item)"
                v-for="item in filteredNavigation"
                :key="item.name"
                :class="[
                    'group relative flex w-full items-center p-4 transition',
                    'first:rounded-t',
                    item.enabled.desktop && 'hover:bg-gray-150 focus-visible:ring-2 focus-visible:ring-blue-600',
                    item.enabled.desktop && !item.current && 'text-gray-600',
                    item.enabled.desktop && item.current && 'text-blue-600',
                    !item.enabled.desktop && 'pointer-events-none text-gray-400',
                ]"
                :href="item.href"
                @click="onClick(item)"
            >
                <AspectIndicator :active="item.current" attached-on="left" />
                <AspectIcon
                    v-if="item.icon"
                    :class="[
                        'mr-2 size-5 shrink-0',
                    ]"
                    :name="item.icon"
                    aria-hidden="true"
                />
                {{ item.name }}
            </component>
        </div>

        <div class="flex flex-col items-center justify-center gap-2 rounded-b border-t border-gray-200 bg-gray-50 px-2 pb-2 pt-4 text-center text-gray-500">
            <AspectLogo color="unset" class="w-32" />
            <small>&copy; Copyright Aspect Systems 2022 - {{ new Date().getFullYear() }}</small>
        </div>
    </nav>
</template>

<script lang="ts" setup>
    import { Link } from '@inertiajs/vue3';
    import { useNavigation } from '@/app/composables/use-navigation.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectIndicator from '@aspect/shared/components/aspect-indicator.vue';
    import AspectLogo from '@aspect/shared/components/aspect-logo.vue';

    const emit = defineEmits<{
        itemClick: [];
    }>();

    const { navigation } = useNavigation();

    const filteredNavigation = computed(() => {
        return navigation.value.filter((item) => item.visible.desktop);
    });

    function onClick(item) {
        if (item.enabled.desktop) {
            emit('itemClick');
        }
    }

    function componentTag(item) {
        if (item.enabled.desktop) {
            return item.external ? 'a' : Link;
        }

        return 'span';
    }
</script>

<template>
    <AspectDropdown :side-offset="16" align="end">
        <template #trigger>
            <AspectButtonAttached class="group flex items-center justify-between rounded-r border-l border-gray-200 p-0 text-left">
                <div class="flex items-center gap-4">
                    <AspectImage
                        subject="person"
                        :class="[
                            'w-16 self-stretch border-r border-gray-200',
                            'hidden sm:flex',
                        ]"
                    />
                    <div
                        :class="[
                            'flex flex-col',
                            'px-4 sm:pl-0',
                        ]"
                    >
                        <div class="font-normal text-gray-800">
                            {{ user.name }}
                        </div>
                        <div class="max-w-[16ch] truncate text-2xs font-normal text-gray-600 group-hover:text-gray-800">
                            {{ pageProps.tenant.name }}
                        </div>
                    </div>
                </div>
            </AspectButtonAttached>
        </template>
        <template #content>
            <AspectDropdownItem
                class="py-2"
                @click="swapLocale"
            >
                <AspectIcon class="size-4" name="translate-text" />
                {{ opposingLocales[currentLocale].name }}
            </AspectDropdownItem>
            <AspectDropdownItem
                v-if="user.tenantsCount && user.tenantsCount > 1"
                class="py-2"
                :as="Link"
                :href="route('tenants.index')"
            >
                <AspectIcon class="size-4" name="building-office" />
                {{ t('Change organization') }}
            </AspectDropdownItem>
            <AspectDropdownItem class="py-2" @click="onLogout">
                <AspectIcon class="size-4" name="logout-1" />
                {{ t('Logout') }}
            </AspectDropdownItem>
        </template>
    </AspectDropdown>
</template>

<script lang="ts" setup>
    import { route } from 'ziggy';
    import { Link, router } from '@inertiajs/vue3';
    import { currentLocale, t } from '@aspect/shared/plugins/i18n.ts';

    import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';
    import useChangeLocale from '@aspect/shared/composables/use-change-locale.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectDropdown from '@aspect/shared/components/aspect-dropdown.vue';
    import AspectDropdownItem from '@aspect/shared/components/aspect-dropdown-item.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectImage from '@aspect/shared/components/aspect-image.vue';

    import type { AuthUserData } from '@aspect/shared/types/generated';

    const pageProps = usePageProps();
    const user = computed(() => pageProps.value.user as AuthUserData);

    const { opposingLocales, swapLocale } = useChangeLocale();

    // LOGOUT
    function onLogout() {
        router.post(route('logout'));
    }
</script>

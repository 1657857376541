import { usePageProps } from '@aspect/shared/composables/use-page-props.ts';

export const can = (permission: string, resource?: any): boolean => {
    const pageProps = usePageProps();

    if (!pageProps.value.user || !pageProps.value.tenant) {
        return false;
    }

    if (!resource && pageProps.value.user.permissions) {
        return pageProps.value.user.permissions[permission] || false;
    }

    if (typeof resource == 'object' && 'permissions' in resource && permission) {
        return resource.permissions[permission] || false;
    }

    return false;
};

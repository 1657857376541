<template>
    <section class="group/console relative flex flex-col">
        <!-- Collapsed Console -->
        <code
            v-if="!expanded"
            class="flex flex-col gap-4 border-t border-gray-200 bg-gradient-to-t from-gray-150 to-gray-50 p-8"
        >
            <samp class="flex items-start gap-2">
                <figure>
                    <AspectIcon
                        v-if="latestEvent?.status"
                        class="mt-0.5 size-4 text-gray-500"
                        :name="icons[latestEvent?.status]"
                    />
                    <AspectSpinner
                        v-else
                        class="mt-0.5 size-4"
                    />
                </figure>
                <span class="line-clamp-1 text-ellipsis">
                    {{ latestEvent?.message }}
                </span>
            </samp>
        </code>

        <!-- Expanded Console -->
        <code
            v-if="expanded"
            :class="[
                'flex flex-col gap-4 overflow-y-auto border-t border-gray-200 bg-gradient-to-t from-gray-150 to-gray-50 p-8',
                maxHeightList,
            ]"
        >
            <TransitionGroup
                enter-active-class="transition duration-300 delay-500"
                enter-from-class="opacity-0 -translate-y-1"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition duration-300 delay-500"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-1"
            >
                <samp
                    v-for="event in events"
                    :key="event.id"
                    class="flex items-start justify-between gap-2"
                >
                    <div class="flex grow items-start gap-2">
                        <figure>
                            <AspectIcon
                                v-if="event.status"
                                class="mt-0.5 size-4 text-gray-500"
                                :name="icons[event.status]"
                            />
                            <AspectSpinner
                                v-else
                                class="mt-0.5 size-4"
                            />
                        </figure>
                        <span>
                            {{ event.message }}
                        </span>
                    </div>

                    <AspectData class="shrink-0 grow-0 text-right text-gray-500" type="time">
                        {{ formatTime(event.timestamp, true) }}
                    </AspectData>
                </samp>
            </TransitionGroup>
        </code>

        <AspectButtonAttached
            :class="[
                'flex items-center justify-center border-gray-200',
                !expanded && 'absolute inset-y-0 right-0 z-10 border-l border-t opacity-0 transition group-hover/console:opacity-100',
                expanded && 'grow border-t',
            ]"
            @click="expanded = !expanded"
        >
            <AspectIcon class="size-4" name="browser-code-1" />
            <span v-if="expanded">
                {{ t('Collapse Console') }}
            </span>
        </AspectButtonAttached>
    </section>
</template>

<script lang="ts" setup>
    import { ref, computed } from 'vue';
    import { orderBy } from 'lodash-es';
    import { t } from '@aspect/shared/plugins/i18n.ts';
    import { formatTime } from '@aspect/shared/utils/date.ts';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectData from '@aspect/shared/components/aspect-data.vue';
    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';

    type ConsoleEventStatus = 'success' | 'warning' | 'error' | 'info';

    export interface ConsoleEvent {
        id: string;
        message: string;
        status: ConsoleEventStatus | null;
        timestamp: Date;
    }

    const props = defineProps<{
        maxHeight?: string;
    }>();

    const maxHeightList = computed(() => {
        return props.maxHeight ?? 'max-h-64';
    });

    const events = ref<ConsoleEvent[]>([]);
    const expanded = ref(false);

    const icons: Record<ConsoleEventStatus, string> = {
        success: 'solid/check-circle',
        warning: 'solid/warning-triangle',
        error: 'solid/delete-circle',
        info: 'solid/information-circle',
    };

    const latestEvent = computed(() => {
        if (!events.value.length) {
            return null;
        }

        return orderBy(events.value, ['timestamp'], ['desc'])[0];
    });

    const succeeded = computed(() => {
        return events.value.every(event => event.status === 'success');
    });

    const loading = computed(() => {
        return events.value.some(event => !event.status);
    });

    function next({ status, message, previousStatus = undefined }: {
        status: ConsoleEventStatus | null;
        message: string;
        previousStatus: ConsoleEventStatus | null | undefined;
    }) {
        const previousEvent = events.value[events.value.length - 1];

        if (previousEvent) {
            if (previousStatus === undefined) {
                previousEvent.status = previousEvent.status || 'success';
            } else {
                previousEvent.status = previousStatus;
            }
        }

        events.value.push({
            id: new Date().getTime().toString(),
            message,
            status,
            timestamp: new Date(),
        });
    }

    function clear() {
        events.value = [];
    }

    defineExpose({
        next,
        clear,
        succeeded,
        loading,
    });
</script>

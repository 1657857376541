<template>
    <aside
        :class="[
            'flex w-full flex-col rounded-t shadow-2xl ring-1 ring-gray-200',
            'h-[calc(100dvh-1rem)] sm:h-auto',
            'max-w-[calc(100vw-2rem)] sm:max-w-xl',
        ]"
    >
        <header class="flex h-14 items-stretch justify-between rounded-t border-b border-gray-200 bg-white">
            <h2 class="flex items-center gap-4 self-center px-4 text-lg">
                <AspectIcon class="size-5" name="scanner-bar-code" />
                {{ t('Scanner') }}
            </h2>

            <div class="flex items-stretch">
                <AspectDropdown v-if="scannerDevices.length > 1" align="end">
                    <template #trigger>
                        <AspectButtonAttached class="border-l border-gray-200 px-5">
                            <AspectIcon class="size-4" name="webcam-video" />
                        </AspectButtonAttached>
                    </template>

                    <template #content>
                        <AspectDropdownCheckboxItem
                            v-for="device in deviceOptions"
                            :key="device.value"
                            :checked="device.value === selectedDevice"
                            @click="onDeviceChange(device.value)"
                        >
                            {{ device.label }}
                        </AspectDropdownCheckboxItem>
                    </template>
                </AspectDropdown>

                <AspectButtonAttached
                    :disabled="!hasTorchCapability"
                    class="rounded-r border-l border-gray-200 px-5"
                    @click="updateTorch(!torchActive)"
                >
                    <AspectIcon class="size-4" name="flash-2" />
                </AspectButtonAttached>
                <AspectButtonAttached class="rounded-r border-l border-gray-200 px-5" @click="onClose">
                    <AspectIcon class="size-4" name="delete-1" />
                </AspectButtonAttached>
            </div>
        </header>

        <div class="relative min-h-32 grow bg-white">
            <Transition
                enter-active-class="transition duration-300"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition duration-300"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
            >
                <div v-if="isValidatingEntry || !isLoaded" class="absolute inset-0 z-10 flex size-full flex-col items-center justify-center bg-white p-12">
                    <div v-if="isValidatingEntry && entryStatus === 'valid'" class="flex flex-col items-center gap-2 pb-14">
                        <AspectIcon v-if="validatedEntry?.state === 'checkedIn'" class="size-20 text-yellow-500" name="warning-triangle" />
                        <AspectIcon v-else class="size-20 text-green-500" name="check-circle" />
                        <span>
                            {{ t('Entry Valid') }}
                        </span>
                        <span>
                            {{ getTypeTranslation }}
                        </span>
                        <AspectData class="text-sm text-gray-500">
                            {{ formatDate(validatedEntry?.dateTime) }} {{ formatTime(validatedEntry?.dateTime) }}
                        </AspectData>
                        <AspectBadge v-if="validatedEntry?.state === 'checkedIn'" color="yellow">
                            {{ t('Already Checked-In') }}
                        </AspectBadge>
                        <AspectBadge v-if="validatedEntry?.state !== 'checkedIn' && checkedIn" color="green">
                            {{ t('Checked-In') }}
                        </AspectBadge>
                    </div>


                    <div v-else-if="isValidatingEntry && entryStatus === 'invalid'" class="flex flex-col items-center gap-2 pb-14">
                        <AspectIcon class="size-20 text-red-500" name="delete-circle" />
                        {{ entryStatusMessage }}
                        <span>
                            {{ getTypeTranslation }}
                        </span>
                        <AspectData v-if="validatedEntry?.dateTime">
                            {{ formatDate(validatedEntry?.dateTime) }} {{ formatTime(validatedEntry?.dateTime) }}
                        </AspectData>
                        <span>
                            {{ validatedEntry?.code }}
                        </span>
                    </div>

                    <div v-else-if="!isValidatingEntry && !isLoaded" class="absolute inset-0 flex size-full flex-col items-center justify-center bg-white p-12">
                        <AspectSpinner class="size-10" />
                        {{ t('Loading scanner') }}
                    </div>

                    <div v-if="isValidatingEntry" class="absolute bottom-0 grid w-full grid-cols-2 divide-x rounded-b border-t">
                        <AspectButtonAttached
                            color="blue"
                            :class="[
                                'grow last:border-l',
                                !showCheckInButton && 'col-span-2',
                            ]"
                            @click="onClearScanner"
                        >
                            {{ t('Scan again') }}
                        </AspectButtonAttached>
                        <AspectButtonAttached
                            v-if="showCheckInButton"
                            color="blue"
                            :loading="checkingIn"
                            :disabled="checkingIn || checkedIn"
                            class="col-span-1 grow border-l"
                            @click="checkIn"
                        >
                            <AspectIcon v-if="checkedIn" class="size-4" name="check-circle" />
                            {{ checkedIn ? t('Checked-In') : t('Check-In') }}
                        </AspectButtonAttached>
                    </div>
                </div>
            </Transition>

            <Transition
                enter-active-class="transition duration-300"
                enter-from-class="opacity-0 translate-y-8"
                enter-to-class="opacity-100 translate-y-0"
                leave-active-class="transition duration-300"
                leave-from-class="opacity-100 translate-y-0"
                leave-to-class="opacity-0 translate-y-8"
            >
                <Scanner
                    v-if="isOpen"
                    ref="aspectScanner"
                    class="aspect-square h-auto overflow-hidden"
                    :selected-device="selectedDevice"
                    @decode="onDecode"
                    @loaded="onLoaded"
                    @error="onError"
                    @set-initial-device="onSetInitialDevice"
                />
            </Transition>
        </div>

        <div>
            <AspectConsole ref="eventConsole" :max-height="'max-h-64'" />
        </div>
    </aside>
</template>

<script lang="ts" setup>
    import { route } from 'ziggy';
    import { ref, onMounted, computed } from 'vue';
    import { getTranslatedValue, t } from '@aspect/shared/plugins/i18n.ts';
    import type { EntryData, EntryValidationResponseData } from '@aspect/shared/types/generated';
    import { useAxiosForm } from '@aspect/shared/composables/use-axios-form';
    import { formatTime, formatDate } from '@aspect/shared/utils/date';

    import AspectIcon from '@aspect/shared/components/aspect-icon.vue';
    import AspectButtonAttached from '@aspect/shared/components/aspect-button-attached.vue';
    import AspectSpinner from '@aspect/shared/components/aspect-spinner.vue';
    import AspectConsole from '@aspect/shared/components/aspect-console.vue';
    import AspectDropdown from '@aspect/shared/components/aspect-dropdown.vue';
    import AspectDropdownCheckboxItem from '@aspect/shared/components/aspect-dropdown-checkbox-item.vue';
    import AspectBadge from '@aspect/shared/components/aspect-badge.vue';
    import Scanner from '@/app/pages/toolkit/scanner/patials/scanner.vue';

    // Props
    // (none)

    // Emits
    const emit = defineEmits(['close']);

    // Constants & Refs
    const validatedEntry = ref<EntryData | null>(null);
    const eventConsole = ref<typeof AspectConsole>();
    const aspectScanner = ref<typeof Scanner>();
    const selectedDevice = ref<string | null>(null);
    const checkingIn = ref(false);
    const checkedIn = ref(false);
    const isOpen = ref(false);
    const scannerCode = ref('');
    const isValidatingEntry = ref(false);
    const entryStatus = ref('');
    const entryStatusMessage = ref('');
    const isLoaded = ref(false);
    const scannerDevices = ref<MediaDeviceInfo[]>([]);
    const hasTorchCapability = ref(false);
    const torchActive = ref(false);

    // Computed
    const deviceOptions = computed(() => {
        return scannerDevices.value.map((device) => ({
            value: device.deviceId,
            label: device.label,
        }));
    });

    const getTypeTranslation = computed(() => {
        return {
            ticket: t('Ticket: :type', { type: getTranslatedValue(validatedEntry.value?.entryable?.name) }),
            bundle: t('Bundle: :type', { type: getTranslatedValue(validatedEntry.value?.entryable?.name) }),
        }[validatedEntry.value?.entryableType ?? ''] ?? '';
    });

    // Functions
    function sendEventMessage(status: string | null, message: string) {
        eventConsole.value?.next({
            status,
            message
        });
    }

    function handleCheckInError() {
        checkingIn.value = false;
        sendEventMessage('error', t('Failed to check-in reservation'));
    }

    function onDeviceChange(deviceId: string | null) {
        isLoaded.value = false;
        selectedDevice.value = deviceId;
        aspectScanner.value?.start(deviceId);
    }

    function onSetInitialDevice(deviceId: string) {
        selectedDevice.value = deviceId;
    }

    function onOpen() {
        isLoaded.value = false;
        isOpen.value = true;
    }

    function onClearScanner() {
        aspectScanner.value?.restart().then(() => {
            setTimeout(() => {
                scannerCode.value = '';
                isValidatingEntry.value = false;
                entryStatus.value = '';
                validatedEntry.value = null;
                checkedIn.value = false;
                checkingIn.value = false;
            }, 100);
        });
    }

    async function checkIn() {
        checkingIn.value = true;
        sendEventMessage('info', t('Checking-in entry :code', { code: validatedEntry.value?.code ?? '' }));

        const form = useAxiosForm({
            entry: validatedEntry.value?.id,
        });

        try {
            const response = await form.put(route('entries.bulkUpdate'), {
                entries: [
                    {
                        id: validatedEntry.value?.id,
                        state: 'checkedIn',
                    },
                ],
            });

            if (response?.status === 200) {
                sendEventMessage('info', t('Checked-in entry :code', { code: validatedEntry.value?.code ?? '' }));
                checkedIn.value = true;
                checkingIn.value = false;
                setTimeout(() => {
                    onClearScanner();
                }, 300);
            } else {
                handleCheckInError();
            }
        } catch (error) {
            if (error) {
                handleCheckInError();
            }
        }
    }

    const showCheckInButton = computed(() => {
        return entryStatus.value === 'valid' && validatedEntry.value?.state !== 'checkedIn';
    });

    function onDecode(code: string) {
        const trimmedCode = code.trim().toUpperCase();
        const isEntry = /^ENTRY:/.test(trimmedCode);
        const rawCode = trimmedCode.replace('ENTRY:', '');

        if (!trimmedCode || rawCode === scannerCode.value) {
            return;
        }

        if (isEntry) {
            scannerCode.value = rawCode;
            isValidatingEntry.value = true;
        } else {
            sendEventMessage('error', t('Invalid format'));
            entryStatus.value = 'invalid';
            isValidatingEntry.value = true;
            return;
        }

        validateEntry();
    }

    async function onLoaded(devices: MediaDeviceInfo[]) {
        sendEventMessage(null, t('Ready to scan'));

        if (!scannerDevices.value.length && devices.length) {
            scannerDevices.value = devices;

            const initialCamera = await aspectScanner.value?.getInitialCamera();

            selectedDevice.value = initialCamera?.deviceId ?? scannerDevices.value[0].deviceId;

            onDeviceChange(selectedDevice.value);
        }

        setTimeout(() => {
            isLoaded.value = true;
            checkTorchCapability();

            if (torchActive.value && hasTorchCapability.value) {
                updateTorch(torchActive.value);
            }
        }, 100);
    }

    function onError(error: Error) {
        sendEventMessage('error', `error: ${error.message}`);
    }

    function onClose() {
        emit('close');
    }

    function updateTorch(newValue: boolean) {
        torchActive.value = newValue;
        aspectScanner.value?.updateTorch(newValue);
    }

    async function getResponse(): Promise<EntryValidationResponseData | null> {
        const form = useAxiosForm({
            code: scannerCode.value,
        });

        const response = await form.post(route('entries.validate', { code: scannerCode.value }));

        return response?.data;
    }

    async function validateEntry() {
        sendEventMessage('info', t('Validating entry'));

        try {
            const response = await getResponse();
            if (response?.status === 'success') {
                validatedEntry.value = response.entry;
                entryStatus.value = 'valid';
                entryStatusMessage.value = t('Entry Valid');

                const message = validatedEntry.value?.state === 'checkedIn'
                    ? `${t('Already checked-in')} : ${scannerCode.value}`
                    : `${t('Entry Valid')} : ${scannerCode.value}`;

                sendEventMessage('success', message);
            } else {
                if (response?.entry) {
                    validatedEntry.value = response.entry;
                }

                entryStatus.value = 'invalid';
                entryStatusMessage.value = {
                    expired: t('Entry Expired'),
                    missing: t('Entry Not Found'),
                    future: t('Entry in the Future'),
                    invalid: t('Entry Invalid'),
                }[response?.message ?? ''] || t('Could not validate entry');

                sendEventMessage('error', entryStatusMessage.value || t('Could not validate entry'));
            }
        } catch (error) {
            if(error) {
                sendEventMessage('error', t('Unknown error'));
            }
        }
    }

    function checkTorchCapability() {
        if (aspectScanner.value) {
            hasTorchCapability.value = aspectScanner.value?.hasTorchCapability();
        }
    }

    // Lifecycle hooks
    onMounted(() => {
        onOpen();

        eventConsole.value?.next({
            status: null,
            message: t('Loading scanner'),
        });
    });
</script>

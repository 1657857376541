<template>
    <div class="relative flex">
        <video ref="scanner" class="size-full object-cover object-center" />

        <!-- HUD Corners -->
        <figure class="absolute left-16 top-16 size-8 rounded-tl border-l-4 border-t-4 border-white opacity-70" />
        <figure class="absolute right-16 top-16 size-8 rounded-tr border-r-4 border-t-4 border-white opacity-70" />
        <figure class="absolute bottom-16 left-16 size-8 rounded-bl border-b-4 border-l-4 border-white opacity-70" />
        <figure class="absolute bottom-16 right-16 size-8 rounded-br border-b-4 border-r-4 border-white opacity-70" />

        <!-- HUD Crosshair -->
        <figure class="absolute left-1/2 top-1/2 h-8 w-1 -translate-x-1/2 -translate-y-1/2 rounded bg-white opacity-70" />
        <figure class="absolute left-1/2 top-1/2 h-1 w-8 -translate-x-1/2 -translate-y-1/2 rounded bg-white opacity-70" />

        <!-- HUD Visor -->
        <figure class="animate-scanning absolute h-1 w-full self-center bg-red-500 shadow-md shadow-red-600" />
    </div>
</template>

<script lang="ts" setup>
    import { BrowserMultiFormatReader, DecodeHintType, BarcodeFormat } from '@zxing/library';
    import { ref, onMounted, onUnmounted } from 'vue';

    const props = defineProps<{
        selectedDevice: string | null;
    }>();

    const emit = defineEmits(['decode', 'loaded', 'error', 'setInitialDevice']);

    defineExpose({
        reset,
        restart: () => {
            return new Promise((resolve) => {
                restart();
                resolve(true);
            });
        },
        getDevices: getVideoInputDevices,
        start,
        stop: () => {
            codeReader.stopContinuousDecode();
        },
        updateTorch,
        hasTorchCapability,
        getInitialCamera
    });

    const hints = new Map();
    hints.set(DecodeHintType.POSSIBLE_FORMATS, [BarcodeFormat.QR_CODE, BarcodeFormat.CODE_128]);

    const scanner = ref<HTMLVideoElement | null>(null);
    const codeReader = new BrowserMultiFormatReader(hints);
    const devices = ref<MediaDeviceInfo[]>([]);

    const isMediaStreamAPISupported = navigator && navigator.mediaDevices && 'enumerateDevices' in navigator.mediaDevices;

    onMounted(async () => {
        if (!isMediaStreamAPISupported) {
            throw new Error('Media Stream API is not supported');
        }

        start(null);

        if (scanner.value) {
            scanner.value.oncanplay = async () => {
                devices.value = await getVideoInputDevices();

                emit('loaded', devices.value);
            };
        }
    });

    function updateTorch(newValue: boolean) {
        if (hasTorchCapability() && scanner.value && scanner.value.srcObject) {
            const track = getVideoTrack();

            if (track) {
                track.applyConstraints({
                    advanced: [{ torch: newValue } as any]
                }).catch(e => {
                    console.error('Failed to turn off/on torch:', e);
                });
            }
        }
    }

    function getInitialCamera() {
        let backCamera = devices.value.find(device => device.label === 'Back Camera');

        if (!backCamera) {
            backCamera = devices.value.find(device => device.label.includes('back') || device.label.includes('arrière'));
        }

        const initialCamera = backCamera ?? devices.value.find(device => device.deviceId !== '') ?? null;
        return initialCamera;
    }

    function hasTorchCapability() {
        if (!scanner.value || !scanner.value.srcObject) {
            return false;
        }

        const track = getVideoTrack();

        if (!track) {
            return false;
        }

        const capabilities = track.getCapabilities();
        return (capabilities as any).torch;
    }

    function getVideoTracks() {
        return (scanner.value!.srcObject as MediaStream)?.getVideoTracks() ?? [];
    }

    function getVideoTrack() {
        const tracks = getVideoTracks();
        const track = tracks.find(track => track.enabled === true);
        return track;
    }

    async function getVideoInputDevices() {
        const devices = await navigator.mediaDevices.enumerateDevices();
        return devices.filter(device => device.kind === 'videoinput');
    }

    onUnmounted(() => {
        reset();
    });

    async function start(deviceId: string | null) {
        const tracks = getVideoTracks();
        for (const track of tracks) {
            track.stop();
        }

        await codeReader.decodeFromVideoDevice(deviceId, scanner.value, (result, error) => {
            if (error) {
                return;
            }

            if (result) {
                emit('decode', result.getText());
                stopScanning();
            }
        });
    }

    function stopScanning() {
        codeReader.stopContinuousDecode();
        reset();
    }

    function reset() {
        try {
            codeReader.reset();
        } catch (error) {
            console.error('Error resetting code reader', error);
        }
    }

    async function restart() {
        stopScanning();
        start(props.selectedDevice);
    }
</script>

<style scoped>
    .animate-scanning {
        animation: scanning 2s infinite;
        animation-fill-mode: both;
    }

    @keyframes scanning {
        0% {
            transform: translateY(-50px);
            opacity: 1;
            /* @TODO Make it fade in and out */
        }
        100% {
            transform: translateY(50px);
            opacity: 0;
        }
    }
</style>

<template>
    <ScannerToolkit v-if="selectedTool === 'scanner'" @close="emit('close')" />
</template>

<script lang="ts" setup>
    import { ref } from 'vue';
    import ScannerToolkit from '@/app/pages/toolkit/scanner/scanner-toolkit.vue';

    const emit = defineEmits(['close']);

    const selectedTool = ref<'scanner'>('scanner');

</script>


<template>
    <Head :title="title" />

    <Header />

    <main class="mt-[var(--header-page-height)] flex grow flex-col p-4">
        <slot />
    </main>

    <Transition
        enter-active-class="ease-out duration-300"
        enter-from-class="opacity-0 translate-y-8"
        enter-to-class="opacity-100 translate-y-0"
        leave-active-class="ease-in duration-200"
        leave-from-class="opacity-100 translate-y-0"
        leave-to-class="opacity-0 translate-y-8"
    >
        <Toolkit v-if="toolkit" class="fixed inset-x-0 bottom-0 z-50 mx-auto h-auto" @close="toolkit = false" />
    </Transition>

    <Footer />

    <Modal />
</template>

<script lang="ts" setup>
    import { Head } from '@inertiajs/vue3';

    import { Modal } from '@aspect/shared/composables/use-modal.ts';

    import Header from '@/app/layouts/main/partials/header.vue';
    import Footer from '@/app/layouts/main/partials/footer.vue';
    import { useTitle } from '@/app/composables/use-title.ts';

    import Toolkit from '@/app/pages/toolkit/toolkit.vue';

    const { title } = useTitle();

    const toolkit = ref(false);

    window.addEventListener('openScanner', () => {
        toolkit.value = true;
    });
</script>

